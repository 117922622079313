@import "./assets/styles/constants.module";
@import "./assets/styles/colors.module";

:root {
  --status-bar-height: 24px;
}

.container {
  box-sizing: border-box;
  max-width: $pageWidth;
  height: 100vh;
  margin: 0 auto;
  background-color: $milk;
}

.touchable-opacity {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.touchable-opacity:active {
  opacity: 0.7;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

.loading-loadable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
