@import '../../assets/styles/colors.module';
@import '../../assets/styles/constants.module';

.loader {
  display: flex;
  div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px;
    background-color: $black;

    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    transform: scale(0);
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  50% {
    transform: scale(1);
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    transform: scale(0);
  }
}
