@font-face {
  font-family: Inter-Bold;
  src: url(./assets/fonts/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(./assets/fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: Inter-Medium;
  src: url(./assets/fonts/Inter-Medium.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}